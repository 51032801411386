<template>
  <div>
   
    <v-container fluid>
       <full-loader v-show="loading"></full-loader>
      <!-- breadcrum and previous router -->
      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>
      <div class="blockuser-contain">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
    enabled: true,
  }"
          :rows="rows"
          :columns="columns"
          :sort-options="{
    enabled: true}"
          @on-cell-click="onCellClick"
        />
      </div>
    </v-container>
  </div>
</template>
<script>
import Mixins from "@/mixin";
import moment from "moment";
import AdminAPI from "@/services/admin_api";
import FullLoader from "../components/full-loader.vue";
export default {
  name: "tnb-logs",
  mixins: [Mixins],
  components: {
    "full-loader": FullLoader,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "System Blocked Users",
          disabled: false,
          to: { name: "email-logs" },
          exact: true
        }
      ],
      isLoading: false,
      columns: [
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Search email", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Fullname",
          field: "fullname",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Search fullname", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Role",
          field: "role",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Search Message", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Created By",
          field: "createdBy.fullname",
        },
        {
          label: "Created At",
          field: "createdAt"
        },
        {
          label: "Periodic block",
          field: "periodic_block"
        },
        {
          label: "Periodic block Remove",
          field: "periodic_block_remove",
          html: true,
          sortable: false,
        },
        {
          label: "Invaild block ",
          field: "invaild_block",
          sortable: false,
        },
        {
          label: "Invaild block Remove ",
          field: "invaild_block_remove",
          sortable: false,
          html: true
        },
        {
          label: "Block Status ",
          field: "block_status",
          sortable: false,
          html: true
        },
        {
          label: "Delete User ",
          field: "delete_user",
          sortable: false,
          html: true
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "", // example: 'name'
            type: "" // 'asc' or 'desc'
          }
        ],
        page: 1,
        perPage: 10,
        loggedUser:{},
      },
      loading: false
    };
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type == 'asc' ? 1 : -1,
            field: params[0].field,
          }
        ]
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    // load items is what brings back the rows from server
    async loadItems() {
     this.loading = true;
     let loggedUser = JSON.parse(localStorage.getItem("userData"));
      if (this.$router.currentRoute.name == "tnb-blocked-users-filter") {
        this.serverParams.type = 1;
      } else {
        this.serverParams.type = 0;
      }
      this.serverParams.loggedUser = loggedUser;
      var data = await AdminAPI.post(
        `blockedUserList`,
        this.serverParams,
        true
      );
      this.loading = false;
      data = data.data;
      //------------------------------------------
      this.totalRecords = data.totalRecords;
      this.rows = data.rows;
      for (const key in this.rows) {
        if (this.rows.hasOwnProperty(key)) {
          const element = this.rows[key];
          if (element.year_block_status == false) {
              this.rows[key].periodic_block = "-";
              this.rows[key].periodic_block_remove = `<button
          type="button"
          class="mr-4 v-btn v-btn--contained theme--light v-size--small primary"
        >
          <span class="v-btn__content">
            Remove Year Block
          </span>
        </button>`;
            } else if (element.periodic_block_on) {
            // var localTime = moment
            //   .utc(`${element.periodic_block_on}`)
            //   .format("YYYY-MM-DD HH:mm:ss");
            // localTime = localTime + " UTC";
            // localTime = moment(new Date(localTime)).format("YYYY-MM-DD HH:mm:ss");
            // var localTime = moment(localTime).fromNow();
            // this.rows[key].periodic_block = localTime;
            var localTime = moment(new Date(element.periodic_block_on)).format("YYYY-MM-DD HH:mm:ss")
            var localTime1 = moment(localTime).fromNow();
            this.rows[key].periodic_block = localTime1;
            var dif = moment().diff(element.periodic_block_on, "minutes");
            var timeoverFlag = dif >= 0 ? true : false;
            if (element.year_block_status == false) {
              this.rows[key].periodic_block_remove = `<button
          type="button"
          class="mr-4 v-btn v-btn--contained theme--light v-size--small primary"
        >
          <span class="v-btn__content">
            Remove Year Block
          </span>
        </button>`;
            } else if (timeoverFlag) {
              this.rows[key].periodic_block_remove = `<button
          type="button"
          class="mr-4 v-btn v-btn--contained theme--light v-size--small warning"
        >
          <span class="v-btn__content">
            Unblock
          </span>
        </button>`;
            } else {
              this.rows[key].periodic_block_remove = `No Action Needed`;
            }
          } else {
            this.rows[key].periodic_block = "Not Blocked";
            this.rows[key].periodic_block_remove = `No Action Needed`;
          }
          if (element.year_block_status == false) { 
            this.rows[key].invaild_block = "-";
            this.rows[key].invaild_block_remove = `-`;
          } else if (element.invalid_login_block) {
            this.rows[key].invaild_block = "Blocked";
            this.rows[key].invaild_block_remove = `<button
          type="button"
          class="mr-4 v-btn v-btn--contained theme--light v-size--small warning"
        >
          <span class="v-btn__content">
            Unblock
          </span>
        </button>`;
          } else {
            this.rows[key].invaild_block = "Not Blocked";
            this.rows[key].invaild_block_remove = `No Action Needed`;
          }
          if (element.year_block_status == false) { 
            this.rows[key].block_status = "-"
          } else if (element.is_active) {
            this.rows[key].block_status = `<button
          type="button"
          class="mr-4 v-btn v-btn--contained theme--light v-size--small error"
        >
          <span class="v-btn__content">
            Block
          </span>
        </button>`;
          } else {
            this.rows[key].block_status = `<button
          type="button"
          class="mr-4 v-btn v-btn--contained theme--light v-size--small warning"
        >
          <span class="v-btn__content">
            Unblock
          </span>
        </button>`;
          }
          this.rows[key].delete_user = `<button
          type="button"
          class="mr-4 v-btn v-btn--contained theme--light v-size--small error"
        >
          <span class="v-btn__content">
            Delete
          </span>
        </button>`;
          var localCtTime = moment(new Date(element.createdAt)).format("YYYY-MM-DD HH:mm:ss");
            // .utc(`${element.createdAt}`)
            // .format("YYYY-MM-DD HH:mm:ss");
          // localCtTime = localCtTime + " UTC";
          // localCtTime = moment(localCtTime).format("YYYY-MM-DD HH:mm:ss");
          this.rows[key].createdAt = element.createdAt
            ? localCtTime
            : "Date Not available";
        }
      }
    },
    async remove_block(type, _id, blockType) {
      try {
        this.loading = true;
        var data = await AdminAPI.get(
          `RemoveUserblocked?type=${type}&_id=${_id}&blockType=${blockType}`,
          true
        );
        this.loading = false;
        data = data.data;
        await this.loadItems();
      } catch (error) {
        throw error;
      }
    },
    async blockActions(type, _id) {
      try {
        this.loading = true;
        var data = await AdminAPI.get(
          `block-user?type=${type}&_id=${_id}`,
          true
        );
        this.loading = false;
        data = data.data;
        await this.loadItems();
      } catch (error) {
        throw error;
      }
    },
    async deleteUser(_id) {
      try {
        this.$swal({
          title: "Delete User",
          text: `Do you want to delete this User? Once it is deleted,Can't revert back!!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, keep it"
        }).then(async result => {
          if (result.value) {
            this.loading = true;
            var data = await AdminAPI.delete(`delete-user?_id=${_id}`, true);
            this.loading = false;
            data = data.data;
            await this.loadItems();
            this.$swal("Deleted!", "Seleted user has been deleted.", "success");
          } else {
            this.$swal("Cancelled", "Your User is safe :)", "error");
          }
        });
      } catch (error) {
        throw error;
      }
    },
    async onCellClick(row, column, rowIndex) {
       if (
        row.event.target.innerText == "UNBLOCK" &&
        row.column.field == "periodic_block_remove"
      ) {
        await this.remove_block(1, row.row._id);
      } else if (
        row.event.target.innerText == "REMOVE YEAR BLOCK" &&
        row.column.field == "periodic_block_remove"
      ) {
        await this.remove_block(1, row.row._id, "year_block");
      } else if (
        row.event.target.innerText == "UNBLOCK" &&
        row.column.field == "invaild_block_remove"
      ) {
        await this.remove_block(2, row.row._id);
      } else if (
        row.event.target.innerText == "UNBLOCK" &&
        row.column.field == "block_status"
      ) {
        await this.blockActions(1, row.row._id);
      } else if (
        row.event.target.innerText == "BLOCK" &&
        row.column.field == "block_status"
      ) {
        await this.blockActions(2, row.row._id);
      } else if (
        row.event.target.innerText == "DELETE" &&
        row.column.field == "delete_user"
      ) {
        await this.deleteUser(row.row._id);
      }
    }
  },
  async mounted() {
    // await this.loadItems();
  },
  watch: {
    $route(to, from) {
      this.loadItems();
    }
  }
};
</script>

<style scoped>
.download-contain {
  float: right;
}
</style>
